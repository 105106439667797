<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            text=""
            default-href="/"
          />
        </ion-buttons>
        <ion-title> {{ $t('verifyEmail.title') }} </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="pt-5">
      <section class="tablet-centered">
        <h4 class="ion-padding-start ion-padding-end ">
          {{ $t('verifyEmail.subtitle') }}
        </h4>

        <ion-card
          v-if="isError"
          color="danger"
        >
          <ion-card-header>
            <ion-card-subtitle> {{ errorMessage }} </ion-card-subtitle>
          </ion-card-header>

          <ion-card-content v-if="!tokenVerified">
            <div>
              <ion-button
                router-link="/login"
                mode="ios"
                size="small"
              > {{ $t('verifyEmail.pleaseLogin') }} </ion-button>
              <span>
                {{ $t('verifyEmail.linkExpired') }}
              </span>
            </div>
          </ion-card-content>
        </ion-card>

        <div class="mt-6 is-flex ion-justify-content-center">
          <IonButton
            router-link="/"
            mode="ios"
          >Home</IonButton>
        </div>
      </section>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonButton,
  IonIcon,
  IonBackButton,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonHeader,
} from '@ionic/vue';

import axios from '@/http';
import auth from '@/plugins/auth';

import { ref, computed } from '@vue/reactivity';

import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n/index';
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
import useRequestToast from "@/composables/useRequestToast";
import useAppStorage from "@/composables/useAppStorage";

import { DEEP_LINK_REDIRECT_STORAGE_KEY, NOT_FOUNT_REDIRECT_STORAGE_KEY } from "@/config/constants";
import { VERIFY_EMAIL } from "@/config/api";

export default {
  name: 'Contact',
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonIcon,
    IonBackButton,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,

    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const store = useStore();
    const { removeKey } = useAppStorage();


    const { openSuccessToast } = useRequestToast();


    const isError = ref(false);
    const errorMessage = ref(t('verifyEmail.errorMessage'));
    const tokenVerified = ref(false);

    const isLogged = computed(() => store.getters['authentication/isLogged']);
    const userId = computed( () => store.getters['authentication/getUser'].id);

    const isUserIdMatch = () => {
      return isLogged.value && (
        userId.value !== undefined && userId.value === parseInt(route.query.id)
      );
    };


    const verifyEmail = () => {
      isError.value = false;
      const params = route.query;

      axios.get(VERIFY_EMAIL, { params })
        .then(() => {
          openSuccessToast(t('register.emailVerified'));
          isError.value = false;

          // prevent redirecting here from deeplink
          removeKey(NOT_FOUNT_REDIRECT_STORAGE_KEY);
          removeKey(DEEP_LINK_REDIRECT_STORAGE_KEY);

          setTimeout(() => {
            if (isUserIdMatch()) {
              store.dispatch('authentication/fetchUser');
            }
            router.push('/');
          }, 1000);
        })
        .catch((error) => {
          isError.value = true;

          if (error?.response?.data?.errors?.verified) {
            tokenVerified.value = true;
          }

          const specificMessage = error?.response?.data?.errors?.signature;
          if (specificMessage) {
            errorMessage.value = specificMessage;
          } else {
            errorMessage.value = t('verifyEmail.errorMessage');
          }
        });
    };

    onMounted(() => {
      const sameUser = isUserIdMatch();

      if (isLogged.value && !sameUser) {
        auth.drivers.router.isRedirect = false;
        store.dispatch('authentication/logout', true).finally(() => {
          auth.drivers.router.isRedirect = true;
        });
        // on reload page will be refreshed;
      } else {
        verifyEmail();
      }
    });

    return {
      errorMessage,
      isError,
      tokenVerified,
    };
  },
};
</script>
