<template>
  <ion-page>
    <ion-content class="ion-padding ion-padding-end pt-5">
      <div class="main-content">
        <div class="is-flex ion-align-items-center ion-justify-content-center is-flex-direction-column ion-margin-bottom">
          <p class="mb-1 fz-24 fw-300">{{ $t('profile.welcomeText') }}</p>
          <h2 class="title fw-400">Medicast</h2>
        </div>

        <div class="is-flex is-flex-direction-column w-100">
          <AppInput
            :value="getUser.email"
            class="w-100 ion-margin-bottom"
            disabled
            :icon="mailOutline"
            :placeholder="$t('profile.emailLabel')"
          />

          <AppInput
            :value="postalCodeValue"
            class="w-100"
            :icon="homeOutline"
            maxlength="7"
            :placeholder="$t('profile.postalCodeLabel')"
            @input:update="postalCodeValue = $event"
          />

          <MobileNumberInput />

          <div
            v-if="!getUserProfessions.length"
            class="ion-padding-top ion-padding-bottom"
          >
            <h6 class="ion-text-center">
              {{ $t('verifyProfile.bigNumber.label') }}
            </h6>

            <div class="is-flex ion-margin-bottom">
              <ion-button
                mode="ios"
                class="w-50 m-0 mt-3 mr-1"
                color="primary fw-500"
                :fill="isBigNumberAvailable === 1 ? 'solid' : 'clear'"
                @click="handleBigAvailableChange(1)"
              >
                {{ $t('verifyProfile.bigNumber.yes') }}
              </ion-button>

              <ion-button
                mode="ios"
                class="w-50 m-0 mt-3  fw-500"
                color="primary"
                :fill="isBigNumberAvailable === 0 ? 'solid' : 'clear'"
                @click="handleBigAvailableChange(0)"
              >
                {{ $t('verifyProfile.bigNumber.no') }}
              </ion-button>
            </div>

            <div>
              <transition
                name="fade"
                mode="out-in"
              >
                <div
                  :key="1"
                  v-if="isBigNumberAvailable > 0"
                >
                  <!--                  <div class="is-flex ion-margin-bottom">-->
                  <!--                    <AppInput-->
                  <!--                      :value="bigNumberValue"-->
                  <!--                      :icon="schoolOutline"-->
                  <!--                      placeholder="Enter bignumber"-->
                  <!--                      class="no-m-input"-->
                  <!--                      style="width: 100%"-->
                  <!--                      @input:update="bigNumberValue = $event"-->
                  <!--                    />-->

                  <!--                    <span class="pre-input-text big-icon is-flex ion-align-items-center p-2">-->
                  <!--                      <IonIcon-->
                  <!--                        v-if="bigNumberValid !== false"-->
                  <!--                        :icon="checkmarkDone"-->
                  <!--                        :color="bigNumberValid ? 'success' : null"-->
                  <!--                      />-->
                  <!--                      <IonIcon-->
                  <!--                        v-if="bigNumberValid === false"-->
                  <!--                        color="danger"-->
                  <!--                        :icon="closeOutline"-->
                  <!--                      />-->
                  <!--                    </span>-->
                  <!--                  </div>-->


                  <app-input
                    :value="bigNumberValue"
                    :minlength="MIN_BIGNUMBER_LENGTH"
                    :maxlength="MAX_BIGNUMBER_LENGTH"
                    class="w-100"
                    type="text"
                    :label="$t('verifyProfile.bigNumber.title')"
                    expanded
                    :icon="schoolOutline"
                    :errors="fieldErrors.bignumber"
                    :placeholder="$t('verifyProfile.bigNumber.enter')"
                    :loading="verifingBignumber"
                    @blur="validateCharactersCount"
                    @input:update="validateBigNumber"
                  >

                    <template #right-addon>
                      <p class="status-icon">
                        <IonIcon
                          :icon="checkmarkDone"
                          :color="bigNumberValid ? 'success' : null"
                        />
                      </p>
                    </template>
                  </app-input>


                  <AppInput
                    :value="currentProfession"
                    :label="$t('verifyProfile.profession.label')"
                    :placeholder="$t('verifyProfile.bigNumber.enter')"
                    :errors="fieldErrors.profession_id"
                    disabled
                    class="no-m-input"
                    style="width: 100%"
                  />



                  <!--                  <template v-if="fieldErrors.bignumber_taken">-->
                  <!--                    <ion-button-->
                  <!--                      mode="ios"-->
                  <!--                      color="danger"-->
                  <!--                      class="mt-5"-->
                  <!--                      expand="block"-->
                  <!--                      @click="showBigTakenModal"-->
                  <!--                    >-->
                  <!--                      <ion-icon-->
                  <!--                        slot="start"-->
                  <!--                        name="alert-circle-outline"-->
                  <!--                      />-->

                  <!--                      {{ $t('profile.bignumberTakenDescr.buttonLabel') }}-->
                  <!--                    </ion-button>-->
                  <!--                  </template>-->
                </div>

                <div v-else-if="isBigNumberAvailable === 0">
                  <keep-alive>
                    <div
                      class="ion-padding-bottom"
                      :key="2"
                    >
                      <AppInput
                        :value="registrationValue"
                        :placeholder="$t('verifyProfile.bigNumber.registration')"
                        class="no-m-input"
                        style="width: 100%"
                        @input:update="registrationValue = $event"
                      />

                      <div class="ion-padding-top is-flex ion-align-items-center p-inline-0">
                        <ion-icon
                          size="small"
                          :icon="schoolOutline"
                          class="ion-margin-end ion-margin-start select-icon"
                        />

                        <AppSelect
                          v-model="selectedProfessionId"
                          class="w-100"
                          :placeholder="$t('verifyProfile.profession.placeholder')"
                          :items="getProfessionFromOccupation"
                        />
                      </div>
                      <div class="ion-padding-start">
                        <AppErrorField :errors="fieldErrors.profession_id" />
                      </div>

                    </div>
                  </keep-alive>
                </div>
              </transition>
            </div>
          </div>
        </div>

        <div
          class="pt-5"
          style="margin-top: auto"
        >
          <ion-row>
            <ion-col>
              <ion-button
                @click="confirmSkip"
                color="light"
                expand="block"
                mode="ios"
              >
                {{ $t('commonKeys.skip') }}
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button
                expand="block"
                :disabled="isBigNumberAvailable === -1"
                mode="ios"
                @click="verifyProfile"
              >
                {{ $t('commonKeys.verifyBtn') }}
              </ion-button>
            </ion-col>
          </ion-row>
        </div>
      </div>

      <IonLoading :is-open="loading"/>
    </ion-content>
  </ion-page>
</template>

<script>
import AppInput from '@/components/common/AppInput.vue';

import {
  IonContent,
  IonPage,
  IonCol,
  IonRow,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonLoading,
  alertController,
} from '@ionic/vue';


import { mailOutline, checkmarkDone, closeOutline, schoolOutline, homeOutline } from 'ionicons/icons';

import MobileNumberInput from "@/components/profile/MobileNumberInput";
import AppErrorField from "@/components/common/AppErrorField";
import AppSelect from "@/components/common/AppSelect";
import useRequestToast from "@/composables/useRequestToast";
import { mapGetters } from 'vuex';
import debounce from "lodash/debounce";

const MIN_BIGNUMBER_LENGTH = 9;
const MAX_BIGNUMBER_LENGTH = 11;
const BIGNUMBER_DEBOUNCE_TIMEOUT = 1000;


export default {
  name: "VerifyModal",
  components: {
    AppErrorField,
    MobileNumberInput,
    IonContent,
    IonPage,
    IonCol,
    IonRow,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonIcon,
    IonLoading,
    AppInput,
    AppSelect,
  },

  data () {
    const { openErrorToast } = useRequestToast();

    return {
      fieldErrors: {
        bignumber: [],
        profession_id: [],
      },
      isBigNumberAvailable: -1,
      bigNumberValue: null,
      bigNumberValid: false,
      registrationValue: null,

      verifingBignumber: false,

      selectedProfessionId: null,

      MIN_BIGNUMBER_LENGTH,
      MAX_BIGNUMBER_LENGTH,

      loading: false,

      openErrorToast,

      mailOutline,
      checkmarkDone,
      closeOutline,
      schoolOutline,
      homeOutline,
    };
  },

  computed: {
    ...mapGetters('profile', ['getProfessionFromOccupation', 'getProfileDetails', 'getUserProfessions']),
    ...mapGetters('authentication', ['getUser']),
    currentProfession () {
      return this.getProfessionFromOccupation.find(p => p.id === this.selectedProfessionId)?.name ?? null;
    },

    postalCodeValue: {
      get() { return this.getProfileDetails.postal_code; },

      set (value) { this.$store.commit('profile/updateProfileDetails', { postal_code: value });},
    },
  },

  methods: {
    async confirmSkip() {
      const alert = await alertController
        .create({
          header: this.$t('verifyProfile.skipConfirm.message'),
          message: this.$t('verifyProfile.skipConfirm.messageBody'),
          buttons: [
            { text: this.$t('commonKeys.cancel') },
            {
              text: this.$t('commonKeys.skip'),
              handler: this.redirectToCompleteProfile,
            },
          ],
        });
      return alert.present();
    },

    handleBigAvailableChange (newValue) {
      if (this.isBigNumberAvailable === newValue) {
        return;
      }

      this.isBigNumberAvailable = newValue;
      this.bigNumberValue = null;
      this.registrationValue = null;
      this.selectedProfessionId = null;
    },


    verifyProfile() {
      this.loading = true;

      this.$store.dispatch('profile/verifyAccount', this.getPostObject())
        .then(async () => {
          this.redirectToCompleteProfile();
        })
        .catch((err) => {
          this.openErrorToast(err);
          this.fieldErrors = err?.response?.data?.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getPostObject () {
      const mobileValue = this.getProfileDetails.mobile;

      const payloadWithoutProfessions = {
        postal_code: this.postalCodeValue,
        mobile: mobileValue ? parseInt(mobileValue) : null,
        otp: mobileValue ? parseInt(this.getProfileDetails.otp) : null,
      };

      const payloadWithProfessions = {
        isBignumberAvailable: this.isBigNumberAvailable,
        bignumber: this.isBigNumberAvailable === 1 ? String(this.bigNumberValue) : null,
        registration_number: this.registrationValue,
        profession_id: this.selectedProfessionId ? this.selectedProfessionId : null,
      };

      if (this.getUserProfessions.length) {
        return payloadWithoutProfessions;
      }

      return Object.assign({}, payloadWithoutProfessions, payloadWithProfessions);
    },

    validateCharactersCount (event) {
      if (event.target.value.length > 0 && event.target.value.length < MIN_BIGNUMBER_LENGTH) {
        this.fieldErrors.bignumber = [this.$t('profile.invalidBigNumberMessage')];
      }
    },
    validateBigNumber (event) {
      this.bigNumberValue = event;
      event = String(event);
      this.selectedProfessionId = null;

      this.fieldErrors.bignumber = null;

      if (event.length >= MIN_BIGNUMBER_LENGTH) {
        this.verifingBignumber = true;
        this.verifyBigNumber();
      }
    },
    verifyBigNumber: debounce(function () {
      this.verifingBignumber = true;
      this.selectedProfessionId = null;
      this.bigNumberValid = false;

      this.$store.dispatch('profile/setBignumberDetails', {
        bignumber: this.bigNumberValue,
        skipCache: false,
      },
      )
        .then(({ data }) => {
          this.selectedProfessionId = data.professionId;
          this.bigNumberValid = true;
        })
        .catch((error) => {
          this.fieldErrors = error?.response?.data?.errors;
        })
        .finally(() => {
          this.verifingBignumber = false;
        })
      ;
    }, BIGNUMBER_DEBOUNCE_TIMEOUT),


    async fetchProfileData () {
      const data = await this.$store.dispatch('profile/setProfileDetails');

      const verifyPageCompleted = Boolean(data.professions?.length > 0 && data.profileDetails?.postal_code);
      const completeProfilePageCompleted = Boolean(this.getProfileDetails?.first_name && this.getProfileDetails?.last_name);

      if (verifyPageCompleted && completeProfilePageCompleted) {
        await this.redirectToHome();
      }

      if (verifyPageCompleted && !completeProfilePageCompleted) {
        await this.redirectToCompleteProfile();
      }

      this.$store.dispatch('profile/setProfessionsFromOccupations');
    },

    redirectToCompleteProfile () {
      return this.$router.push({ name: 'CompleteProfile' });
    },

    redirectToHome () {
      return this.$router.push('/');
    },
  },

  ionViewDidEnter() {
    this.fetchProfileData();
  },
};
</script>

<style scoped lang="scss">
    .main-content {
        padding-top: 10vh;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .title {
        font-size: 50px;
    }

    .subtitle {
        padding-left: 40px;
    }

    @media screen and (max-width: 320px) {
        .title {
            font-size: 60px;
        }
    }


    .status-icon {
      font-size: 24px;
      display: flex;
      align-self: center;
    }
</style>
